import * as React from 'react';
import { Link, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import Bio from '../components/bio';
import Layout from '../components/layout';
import Seo from '../components/seo';

function BlogIndex({ data, location }) {
  const posts = data.allMarkdownRemark.nodes;
  const title = 'All blog posts';

  if (posts.length === 0) return null;

  return (
    <Layout location={location} title={title}>
      <Seo title={title} />
      <Bio />
      <ol style={{ listStyle: 'none' }} className="blog-post-summaries">
        {posts.map((post) => (
          <li key={post.fields.slug} className="blog-post-summary">
            {post.frontmatter.featuredImage && (
            <div className="featured-image">
              <Link to={post.fields.slug} itemProp="url">
                <GatsbyImage
                  image={post.frontmatter.featuredImage.childImageSharp?.gatsbyImageData}
                />
              </Link>
            </div>
            )}
            <article>
              <h3>
                <Link to={post.fields.slug} itemProp="url">
                  <span itemProp="headline">{post.frontmatter.title}</span>
                </Link>
              </h3>
              <small>{post.frontmatter.date}</small>
              <p
                dangerouslySetInnerHTML={{
                  __html: post.frontmatter.description || post.excerpt,
                }}
                itemProp="description"
              />
            </article>
          </li>
        ))}
      </ol>
    </Layout>
  );
}

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: {slug: {ne: "/"}} }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          featuredImage {
            childImageSharp {
              gatsbyImageData(width: 600)
            }
          }
        }
      }
    }
  }
`;
